// Entry point for the build script in your package.json

import 'core-js/stable'

// Rails functionality
import Rails from "@rails/ujs"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails
window.Turbo = Turbo

require("@rails/activestorage").start()
require("trix")

import toastr from 'toastr'
window.toastr = toastr

import LocalTime from "local-time"
LocalTime.start()

// ADD YOUR JAVASCRIPT HERE

// Start Rails UJS
Rails.start()
